import { put, takeLatest, takeLeading, all, call, select } from 'redux-saga/effects';
import { actions } from 'react-redux-form';
import {
	CHECK_EXISTING_USER,
	CHECK_EXISTING_USER_SUCCESS,
	CHECK_EXISTING_USER_ERROR,
	GET_OPTIONS,
	GET_OPTIONS_SUCCESS,
	GET_OPTIONS_ERROR,
	REFRESH_USER,
	REFRESH_USER_SUCCESS,
	REFRESH_USER_ERROR,
	FREE_ACCESS,
	FREE_ACCESS_SUCCESS,
	FREE_ACCESS_ERROR,
	AUDIT,
	AUDIT_SUCCESS,
	AUDIT_ERROR,
	GET_TRIAL_SCHEDULES,
	TRIAL_SCHEDULES_RECEIVED,
	TRIAL_SCHEDULES_ERROR,
	GET_SCHEDULES,
	SCHEDULES_RECEIVED,
	SCHEDULES_ERROR,
	GET_HEALTH_SCORES,
	HEALTH_SCORES_RECEIVED,
	HEALTH_SCORES_ERROR,
	MATCH_SUCCESS,
	MATCH_ERROR,
	GET_MATCH,
	GET_PLAYER,
	PLAYER_SUCCESS,
	PLAYER_ERROR,
	SIGNUP,
	SIGNUP_SUCCESS,
	SIGNUP_ERROR,
	LOGIN,
	LOGIN_SUCCESS,
	LOGIN_ERROR,
	LOGOUT,
	LOGOUT_ERROR,
	SEND_PASSWORD_RESET,
	SEND_PASSWORD_RESET_SUCCESS,
	SEND_PASSWORD_RESET_ERROR,
	VERIFY_PASSWORD_RESET_TOKEN,
	VERIFY_PASSWORD_RESET_TOKEN_SUCCESS,
	VERIFY_PASSWORD_RESET_TOKEN_ERROR,
	RESET_PASSWORD,
	RESET_PASSWORD_SUCCESS,
	RESET_PASSWORD_ERROR,
	CHECK_LOGIN,
	CHECK_LOGIN_SUCCESS,
	CHECK_LOGIN_ERROR,
	MAKE_SAMPLE_TOKEN,
	MAKE_SAMPLE_TOKEN_SUCCESS,
	MAKE_SAMPLE_TOKEN_ERROR,
	GET_PRICES,
	GET_PRICES_SUCCESS,
	GET_PRICES_ERROR,
	PURCHASE_PLAN,
	PURCHASE_PLAN_SUCCESS,
	PURCHASE_PLAN_ERROR,
	TEAM_SCORES,
	TEAM_SCORES_RECEIVED,
	TEAM_SCORES_ERROR,
	VIEW_TEAM,
	VIEW_TEAM_SUCCESS,
	VIEW_TEAM_ERROR,
	TEAM_SCHEDULE,
	TEAM_SCHEDULE_SUCCESS,
	TEAM_SCHEDULE_ERROR,
	GET_DASHBOARD_OPTIONS,
	ADMIN_DASHBOARD_SEARCH_SUBMIT,
	ADMIN_DASHBOARD_CHANGE_SUBMIT,
	ADMIN_DASHBOARD_INSERT_SUBMIT,
	GET_ADMIN_CSV,
	RUN_CALCULATIONS,
	CANCEL_SUBSCRIPTION,
	CANCEL_SUBSCRIPTION_SUCCESS,
	CANCEL_SUBSCRIPTION_ERROR,
	GET_TEAM_HEALTH_SCORE_NFL,
	CREATE_SUBSCRIPTION,
	CREATE_SUBSCRIPTION_SUCCESS,
	CREATE_SUBSCRIPTION_ERROR,
	LOGOUT_SUCCESS,
	SEARCH_GAMES,
	SUBMIT_ADMIN_CHANGES,
	GAME_PLAYER_INFO,
	GAME_PLAYER_INFO_ERROR
} from './constants';
import { request, toLocalTime } from '../components/utils/index';
import * as selectors from './selectors';
import { adminDashboardOptions, adminDashboardSearch, adminDashboardChange, adminDashboardInsert, adminDashboardCSV, adminDashboardRunCalculations, adminTeamHealthScoreNFL, searchGames, submitAdminChanges } from './adminSaga';
// import { result } from "lodash";
// import axios from "axios";

const sportsIndexArr = ['nfl', 'nba', 'soccer'];

function getSportIndex(url) {
	for (let x = 0; x < url.length; x++) {
		if (sportsIndexArr.includes(url[x].toLowerCase())) {
			return {
				sportsID: sportsIndexArr.indexOf(url[x]) + 1,
				sportsName: url[x].toLowerCase()
			};
		}
	}
}

function* checkExistingUser(action) {
	let result;
	const { email } = action.payload;
	try {
		result = yield call(request, `/get/checkusername/${email}`, {
			method: 'GET',
			cache: 'no-cache',
			headers: {
				'cache-control': 'no-cache',
				'Content-Type': 'application/json'
			}
		});
		if (result.data.status) {
			yield put({
				type: CHECK_EXISTING_USER_SUCCESS
			});
		} else {
			yield put({
				type: CHECK_EXISTING_USER_ERROR
			});
		}
	} catch (err) {
		//console.log(err);
		yield put({
			type: CHECK_EXISTING_USER_ERROR
		});
	}
}

function* getOptions() {
	let result;
	const user = yield select(selectors.user);
	try {
		result = yield request({}, `/options/${user?.sportsID || 1}`, 'GET');
		if (result.data.status) {
			yield put({
				type: GET_OPTIONS_SUCCESS,
				payload: {
					states: result?.data?.data?.states,
					nflScoreboard: result?.data?.data?.nflScoreboard || [],
					sportsInfo: result?.data?.data?.sportsInfo
				}
			});
		} else {
			yield put({
				type: GET_OPTIONS_ERROR
			});
		}
	} catch (err) {
		yield put({
			type: GET_OPTIONS_ERROR
		});
	}
}

function* freeAccess(action) {
	let result;
	const freeAccess = yield select(selectors.freeAccess);
	try {
		const data = { freeAccess };
		result = yield request(data, `/freeAccess`, 'POST');
		if (result.data.status) {
			yield put({
				type: FREE_ACCESS_SUCCESS
			});
			window.location.href = '/';
		} else {
			yield put({
				type: FREE_ACCESS_ERROR
			});
		}
	} catch (err) {
		//console.log(err);
		yield put({
			type: FREE_ACCESS_ERROR
		});
	}
}

function* storeAudit(action) {
	const user = yield select(selectors.user);
	let result;
	try {
		const data = {
			user: user?.email || 'guest_user',
			action: action.payload.user_action,
			meta: action.payload.meta || {}
		};
		result = yield request(data, `/audit`, 'POST');
		if (result.data.status) {
			yield put({
				type: AUDIT_SUCCESS
			});
		} else {
			yield put({
				type: AUDIT_ERROR
			});
		}
	} catch (err) {
		//console.log(err);
		yield put({
			type: AUDIT_ERROR
		});
	}
}

function* makeSampleToken(action) {
	let result;
	const existingToken = action.payload;
	try {
		if (!existingToken) {
			result = yield request({}, `/sampleToken`, 'GET');
			if (result.data.status) {
				yield put({
					type: MAKE_SAMPLE_TOKEN_SUCCESS,
					payload: {
						token: result.data?.data
					}
				});
			} else {
				yield put({
					type: MAKE_SAMPLE_TOKEN_ERROR
				});
			}
		} else {
			result = yield request({}, `/checkLogin`, 'GET', existingToken);
			if (!result.data.status) {
				result = yield request({}, `/sampleToken`, 'GET');
			} else {
				yield put({
					type: MAKE_SAMPLE_TOKEN_SUCCESS,
					payload: { token: existingToken }
				});
			}
		}
	} catch (err) {
		yield put({
			type: MAKE_SAMPLE_TOKEN_ERROR
		});
	}
}

function* checkLogin() {
	let user = yield select(selectors.user);
	let result;
	try {
		result = yield request({}, `/checkLogin`, 'GET', user?.token);
		if (result.data.status) {
			yield put({
				type: CHECK_LOGIN_SUCCESS
			});
		} else {
			yield put({
				type: LOGOUT
			});
		}
	} catch (err) {
		yield put({
			type: CHECK_LOGIN_ERROR
		});
	}
}

function* refreshUserInfo() {
	const user = yield select(selectors.user);
	let result;
	try {
		const data = {
			email: user?.email
		};
		result = yield request(data, `/refreshUser/${user?.id}`, 'GET', user?.token);
		if (result.data.status) {
			yield put({
				type: REFRESH_USER_SUCCESS,
				payload: {
					...result.data?.data?.user
				}
			});
			// }
		} else {
			yield put({
				type: REFRESH_USER_ERROR,
				payload: result.data.message
			});
		}
	} catch (err) {
		// console.log(err);
		yield put({
			type: REFRESH_USER_ERROR
		});
	}
}

function* signup(action) {
	const { signup } = action.payload;
	let result;
	try {
		let data = {
			...signup
		};
		result = yield request(data, '/signup', 'POST');
		if (result.data.status) {
			yield put({
				type: SIGNUP_SUCCESS,
				payload: {
					...result?.data?.data
				}
			});
			// Send signup email
			const user = yield select(selectors.user);
			yield request({ email: data.email }, '/mail/signup', 'POST', user.token);
			window.location.href = '/nba/upcominggames';
		} else {
			yield put({
				type: SIGNUP_ERROR,
				payload: { message: result?.data?.message }
			});
		}
	} catch (error) {
		console.log(error);
		if (!result?.data?.status) {
			yield put({
				type: SIGNUP_ERROR,
				payload: { message: result?.data?.message }
			});
			yield put({
				type: AUDIT,
				payload: {
					action: SIGNUP_ERROR,
					meta: error
				}
			});
		}
	}
}

function* login(action) {
	const { login } = action.payload;
	let result;
	try {
		const data = {
			...login
		};
		result = yield request(data, '/login', 'POST');
		if (result.data.status) {
			yield put({
				type: LOGIN_SUCCESS,
				payload: {
					...result?.data?.data
				}
			});
		} else if (result?.data?.error) {
			yield put({
				type: LOGIN_ERROR,
				payload: { message: result?.data?.message }
			});
			yield put(actions.change('alertMessage.error', result?.data?.message));
		}
		window.location.href = '/nfl/upcominggames';
	} catch (error) {
		console.log(error);
		yield put({
			type: LOGIN_ERROR,
			payload: { message: result?.data?.message }
		});
		yield put({
			type: AUDIT,
			payload: {
				action: LOGIN_ERROR,
				meta: error
			}
		});
		yield put(actions.change('alertMessage.error', result?.data?.message));
	}
}

function* sendPasswordReset(action) {
	const { email } = action?.payload;
	let result;
	try {
		const data = {
			email
		};
		result = yield request(data, '/mail/sendPasswordReset', 'POST');
		if (result.data.status) {
			yield put({
				type: SEND_PASSWORD_RESET_SUCCESS,
				payload: { message: result?.data?.message }
			});
			yield put(actions.change('alertMessage.message', result?.data?.message));
		} else {
			yield put({
				type: SEND_PASSWORD_RESET_ERROR,
				payload: { message: result?.data?.message }
			});
			yield put(actions.change('alertMessage.error', result?.data?.message));
		}
	} catch (error) {
		console.log(error);
		yield put({
			type: SEND_PASSWORD_RESET_ERROR,
			payload: { message: result?.data?.message }
		});
		yield put(actions.change('alertMessage.error', result?.data?.message));
	}
}

function* verifyPasswordReset(action) {
	const { token } = action.payload;
	let result;
	try {
		const data = {
			token
		};
		result = yield request(data, '/verifyPasswordReset', 'POST');
		if (result.data.status) {
			yield put({
				type: VERIFY_PASSWORD_RESET_TOKEN_SUCCESS,
				payload: { message: result?.data?.message, email: result.data?.data?.email }
			});
		} else {
			yield put({
				type: VERIFY_PASSWORD_RESET_TOKEN_ERROR,
				payload: { message: result?.data?.message }
			});
			yield put(actions.change('alertMessage.error', result?.data?.message));
		}
	} catch (error) {
		console.log(error);
		yield put({
			type: VERIFY_PASSWORD_RESET_TOKEN_ERROR,
			payload: { message: result?.data?.message }
		});
	}
}

function* resetPassword(action) {
	const { reset_password, token } = action?.payload;
	const passwordReset = yield select(selectors.passwordReset);
	let result;
	try {
		const data = {
			reset_password,
			email: passwordReset.email
		};
		result = yield request(data, '/resetPassword', 'POST', token);
		if (result.data.status) {
			yield put({
				type: RESET_PASSWORD_SUCCESS,
				payload: { message: result?.data?.message }
			});
		} else {
			yield put({
				type: RESET_PASSWORD_ERROR,
				payload: { message: result?.data?.message }
			});
			yield put(actions.change('alertMessage.error', result?.data?.message));
		}
	} catch (error) {
		console.log(error);
		yield put({
			type: RESET_PASSWORD_ERROR,
			payload: { message: result?.data?.message }
		});
		yield put(actions.change('alertMessage.error', result?.data?.message));
	}
}

function* logout() {
	try {
		yield request({}, '/logout', 'GET');
		yield put({
			type: LOGOUT_SUCCESS
		});
		window.location.href = '/';
	} catch (error) {
		yield put({
			type: LOGOUT_ERROR,
			payload: 'Unable to logout'
		});
	}
}

////////////////////////////////////////////////
function* fetchTrialSchedules(action) {
	try {
		// const trialSchedules = yield fetch(`/main/${sport}/trialschedules`).then(res =>
		const trialSchedules = yield fetch(`/main/nfl/trialschedules`).then((res) => res.json());
		yield put({
			type: TRIAL_SCHEDULES_RECEIVED,
			payload: trialSchedules
		});
	} catch (err) {
		yield put({
			type: TRIAL_SCHEDULES_ERROR,
			payload: 'Unable to retrieve trial schedules'
		});
	}
}

function* fetchSchedules() {
	const user = yield select(selectors.user);
	let schedules;
	try {
		schedules = yield request({}, `/main/${user.sportsID || 1}/schedules`, 'GET', user?.token);
		try {
			schedules?.data?.data?.schedules?.map((s) => (s.date_time = toLocalTime(s.date_time)));
		} catch (error) {
			console.log(error);
		}
		yield put({
			type: SCHEDULES_RECEIVED,
			payload: schedules.data?.data
		});
	} catch (err) {
		yield put({
			type: SCHEDULES_ERROR,
			payload: 'Unable to retrieve schedules'
		});
	}
	yield put({
		type: TEAM_SCORES
	});
	try {
		const teamScores = yield request({}, `/main/${user.sportsID || 1}/healthScores`, 'GET', user?.token);
		yield put({
			type: TEAM_SCORES_RECEIVED,
			payload: teamScores?.data?.data
		});
	} catch (err) {
		yield put({
			type: TEAM_SCORES_ERROR,
			payload: 'Unable to retrieve schedules'
		});
	}
	try {
		const players = yield request({}, `/main/players/${user.sportsID || 1}`, 'GET', user?.token);
		yield put({
			type: GAME_PLAYER_INFO,
			payload: players?.data?.data?.playerInfo
		});
	} catch (err) {
		console.log(err);
		yield put({
			type: GAME_PLAYER_INFO_ERROR,
			payload: 'Unable to retrieve players'
		});
	}
}

function* fetchHealthScores(action) {
	const user = yield select(selectors.user);
	const matchID = action.payload.gameID;
	try {
		const data = {
			matchID
		};
		const healthScores = yield request(data, `/main/${user.sportsID || 1}/averagehealthscores`, 'POST', user?.token);
		yield put({
			type: HEALTH_SCORES_RECEIVED,
			payload: healthScores?.data?.data
		});
	} catch (err) {
		yield put({
			type: HEALTH_SCORES_ERROR,
			payload: 'Unable to retrieve health scores'
		});
	}
}

function* fetchMatch(action) {
	const matchID = action.payload.gameID;
	const user = yield select((state) => state.user);
	let match;
	try {
		match = yield request({}, `/main/${user.sportsID || 1}/matchup/${matchID}`, 'GET', user?.token);
		try {
			if (match?.data?.data?.match[0]?.date_time) {
				match.data.data.match[0].date_time = toLocalTime(match.data.data.match[0].date_time);
			}
		} catch (error) {
			console.log(error);
		}
		yield put({
			type: MATCH_SUCCESS,
			payload: match?.data?.data
		});
	} catch (err) {
		yield put({
			type: MATCH_ERROR,
			payload: 'Sorry! Looks like there was an error retreiving the matchup data'
		});
	}
	try {
		const teamScores = yield request({}, `/main/${user.sportsID || 1}/healthScores`, 'GET', user?.token);
		yield put({
			type: TEAM_SCORES_RECEIVED,
			payload: teamScores.data?.data
		});
	} catch (err) {
		yield put({
			type: TEAM_SCORES_ERROR,
			payload: 'Unable to retrieve schedules'
		});
	}
}

function* fetchTeamInfo(action) {
	const teamID = action.payload.teamID;
	const user = yield select((state) => state.user);
	try {
		const viewTeam = yield request({}, `/main/${user.sportsID || 1}/team/${teamID}`, 'GET', user?.token);
		yield put({
			type: VIEW_TEAM_SUCCESS,
			payload: {
				teamInfo: viewTeam?.data?.data?.teamInfo,
				playerInfo: viewTeam?.data?.data.playerInfo
			}
		});
	} catch (err) {
		yield put({
			type: VIEW_TEAM_ERROR,
			payload: 'Unable to retrieve team info'
		});
	}
}

function* fetchTeamSchedule(action) {
	const teamID = action.payload.teamID;
	const user = yield select((state) => state.user);
	try {
		const schedules = yield request({}, `/main/${user.sportsID || 1}/teamschedule/${teamID}`, 'GET', user?.token);
		yield put({
			type: TEAM_SCHEDULE_SUCCESS,
			payload: { teamInfo: schedules?.data?.data }
		});
	} catch (err) {
		yield put({
			type: TEAM_SCHEDULE_ERROR,
			payload: 'Unable to retrieve schedules for team'
		});
	}
}

function* fetchPlayerInfo(action) {
	const { playerID } = action.payload;
	const user = yield select((state) => state.user);
	const url = window.location.pathname.split('/');
	const sportsInfo = yield getSportIndex(url);
	const sportsID = sportsInfo ? sportsInfo.sportsID : 1;
	const sportsName = sportsInfo ? sportsInfo.sportsName : 'nfl';
	try {
		const result = yield request({}, `/main/${user.sportsID || sportsID}/${user.sportsName || sportsName}/playerinfo/${playerID}`, 'GET', user?.token);
		yield put({
			type: PLAYER_SUCCESS,
			payload: result?.data?.data
		});
	} catch (err) {
		yield put({
			type: PLAYER_ERROR,
			payload: 'Unable to retrieve player data'
		});
	}
}

function* getPrices(action) {
	let result;
	try {
		result = yield request({}, `/prices`, 'GET');
		yield put({
			type: GET_PRICES_SUCCESS,
			payload: { prices: result?.data?.data?.prices }
		});
	} catch (err) {
		yield put({
			type: GET_PRICES_ERROR,
			payload: { error: 'Error occured getting prices' }
		});
	}
}

function* actionWatcher() {
	yield takeLeading(CHECK_EXISTING_USER, checkExistingUser);
	yield takeLeading(GET_OPTIONS, getOptions);
	yield takeLeading(CHECK_LOGIN, checkLogin);
	yield takeLeading(REFRESH_USER, refreshUserInfo);
	yield takeLeading(MAKE_SAMPLE_TOKEN, makeSampleToken);
	yield takeLatest(GET_TRIAL_SCHEDULES, fetchTrialSchedules);
	yield takeLatest(GET_SCHEDULES, fetchSchedules);
	yield takeLatest(GET_HEALTH_SCORES, fetchHealthScores);
	yield takeLatest(GET_MATCH, fetchMatch);
	yield takeLatest(GET_PLAYER, fetchPlayerInfo);
	yield takeLatest(GET_PRICES, getPrices);

	// Login/Signup Section
	yield takeLeading(SIGNUP, signup);
	yield takeLeading(LOGIN, login);
	yield takeLeading(LOGOUT, logout);
	yield takeLeading(SEND_PASSWORD_RESET, sendPasswordReset);
	yield takeLeading(VERIFY_PASSWORD_RESET_TOKEN, verifyPasswordReset);
	yield takeLeading(RESET_PASSWORD, resetPassword);

	// Teams Section
	yield takeLatest(VIEW_TEAM, fetchTeamInfo);
	yield takeLatest(TEAM_SCHEDULE, fetchTeamSchedule);

	// Admin Section
	yield takeLeading(GET_DASHBOARD_OPTIONS, adminDashboardOptions);
	yield takeLeading(ADMIN_DASHBOARD_SEARCH_SUBMIT, adminDashboardSearch);
	yield takeLeading(ADMIN_DASHBOARD_CHANGE_SUBMIT, adminDashboardChange);
	yield takeLeading(ADMIN_DASHBOARD_INSERT_SUBMIT, adminDashboardInsert);
	yield takeLeading(GET_ADMIN_CSV, adminDashboardCSV);
	yield takeLeading(RUN_CALCULATIONS, adminDashboardRunCalculations);
	yield takeLeading(GET_TEAM_HEALTH_SCORE_NFL, adminTeamHealthScoreNFL);
	yield takeLeading(SEARCH_GAMES, searchGames);
	yield takeLeading(SUBMIT_ADMIN_CHANGES, submitAdminChanges);

	// Audit Section
	yield takeLeading(AUDIT, storeAudit);

	// Free Access Section
	yield takeLeading(FREE_ACCESS, freeAccess);
}

export default function* rootSaga() {
	yield all([actionWatcher()]);
}
